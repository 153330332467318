import isEmpty from 'lodash/isEmpty';

import { capitalize, getStringWithoutTags, isBrowser } from 'utils';

import { COMPONENTS } from './constants';
import {
  GenerateLeadEventType,
  GTMEvent,
  IAdditionalProductInfo,
  IListingBody,
  IProductBody,
  IProductItem,
  PageViewEventType,
  PartialGTMProduct,
  ProductListingViewEventType,
  ProductViewEventType,
  RetailerClickEventType,
  SearchResultsViewEventType,
} from './model';

class GtmService {
  private eventKeys = {
    event: 'event',
    pageTemplate: 'page_template',
    userId: 'user_id',
    formName: 'form_name',
    searchTerm: 'search_term',
    retailerName: 'retailer_name',
  } as const;

  private eventValues = {
    generateLead: 'generate_lead',
    viewSearchResults: 'view_search_results',
    retailerLinkClick: 'retailer_link_click',
    viewItem: 'view_item',
    viewItemList: 'view_item_list',
  } as const;

  private productKeys = {
    itemId: 'item_id',
    itemName: 'item_name',
    itemBrand: 'item_brand',
    itemVariant: 'item_variant',
    itemCategory: 'item_category',
    itemListName: 'item_list_name',
    itemCategory2: 'item_category2',
  } as const;

  formNames = {
    newsletter: 'newsletter',
    contactUs: 'contact_us',
    competition: 'competition',
    coupon: 'coupon',
  } as const;

  private templateNames = {
    home: 'homepage',
    article: 'article_page',
    product: 'product_page',
    productListing: 'products_list_page',
    search: 'search_results_page',
    contactUs: 'contact_us_page',
    other: 'other_page',
  } as const;

  readonly brandName = 'Vanish';

  readonly iframeMessageFormSubmitted = 'FORM_SUBMITTED';

  emitPageView(templateName: string = ''): void {
    const pageTemplate = (Object.values(this.templateNames) as string[]).includes(templateName)
      ? templateName
      : this.templateNames.other;

    const event: PageViewEventType = {
      [this.eventKeys.pageTemplate]: pageTemplate,
    };

    this.emitEvent(event);
  }

  emitSearchResultsView(searchTerm: string): NodeJS.Timeout | void {
    if (typeof searchTerm !== 'string' || !searchTerm.trim()) return;

    const event: SearchResultsViewEventType = {
      [this.eventKeys.event]: this.eventValues.viewSearchResults,
      [this.eventKeys.searchTerm]: searchTerm.trim().toLowerCase(),
    };

    return this.emitDelayedEvent([event]);
  }

  emitGenerateLead(formName: string): void {
    if (!(Object.values(this.formNames) as string[]).includes(formName)) return;

    const event: GenerateLeadEventType = {
      [this.eventKeys.event]: this.eventValues.generateLead,
      [this.eventKeys.formName]: formName,
    };

    this.emitEvent(event);
  }

  emitRetailerClick(product: Partial<IProductItem>, retailerName: string): void {
    if (typeof retailerName !== 'string' || !retailerName.trim()) return;

    const event: RetailerClickEventType = {
      [this.eventKeys.event]: this.eventValues.retailerLinkClick,
      [this.eventKeys.retailerName]: retailerName.toLowerCase().trim(),
      ...(!isEmpty(product) && {
        ecommerce: {
          items: [this.getGtmProductItem(product)],
        },
      }),
    };

    this.emitEvent({ ecommerce: null });
    this.emitEvent(event);
  }

  emitProductView(productBody: IProductBody[]): NodeJS.Timeout | void {
    if (isEmpty(productBody)) return;

    const gtmProductItems: PartialGTMProduct[] = this.collectProductOptions(productBody);
    if (isEmpty(gtmProductItems)) return;

    const event: ProductViewEventType = {
      [this.eventKeys.event]: this.eventValues.viewItem,
      ecommerce: {
        items: gtmProductItems,
      },
    };

    return this.emitDelayedEvent([{ ecommerce: null }, event]);
  }

  emitProductListingView(listingName: string, listingBody: IListingBody[]): NodeJS.Timeout | void {
    if (typeof listingName !== 'string' || !listingName.trim() || isEmpty(listingBody)) return;

    const collectedProducts = this.collectListingProducts(listingBody);
    if (isEmpty(collectedProducts)) return;

    const gtmProductItems = collectedProducts.map(this.getGtmProductItem.bind(this));

    const event: ProductListingViewEventType = {
      [this.eventKeys.event]: this.eventValues.viewItemList,
      [this.productKeys.itemListName]: capitalize(listingName),
      ecommerce: {
        items: gtmProductItems,
      },
    };

    return this.emitDelayedEvent([{ ecommerce: null }, event]);
  }

  getGtmProductItem({
    sku,
    variant,
    variants,
    productName,
    productCategory,
    title,
    category,
    url,
  }: Partial<IProductItem>): PartialGTMProduct {
    const nameToSend = productName || title;
    const categoryToSend = category || productCategory || this.extractCategoryFromPathname(url);
    const productVariant = variants || variant;

    return {
      [this.productKeys.itemBrand]: this.brandName,
      ...(nameToSend && {
        [this.productKeys.itemName]: capitalize(getStringWithoutTags(nameToSend)).trim(),
      }),
      ...(sku && { [this.productKeys.itemId]: sku }),
      ...(categoryToSend && { [this.productKeys.itemCategory]: capitalize(categoryToSend).trim() }),
      ...(productVariant && { [this.productKeys.itemVariant]: capitalize(productVariant).trim() }),
    };
  }

  enrichShoppingOptions(
    productBody: IProductBody[],
    { name, category, pathname }: IAdditionalProductInfo
  ): void {
    const { shoppingOptionsCard } =
      productBody.find(({ component }) => component === COMPONENTS.shoppingOptions)?.value || {};

    shoppingOptionsCard?.forEach((card) => {
      card.emitGtmEvent = true;
      card.productName = name;
      card.productCategory = category || this.extractCategoryFromPathname(pathname);
    });
  }

  collectProductOptions(productBody: IProductBody[]): PartialGTMProduct[] {
    const productOptionsCards = productBody.find(
      ({ component }) => component === COMPONENTS.shoppingOptions
    )?.value?.shoppingOptionsCard;

    if (!productOptionsCards || isEmpty(productOptionsCards)) return [];

    return productOptionsCards.map(this.getGtmProductItem.bind(this));
  }

  collectListingProducts(listingBody: IListingBody[]) {
    return listingBody.reduce((products: IProductItem[], { component, value }) => {
      if (component === COMPONENTS.productsListing && Array.isArray(value?.cards)) {
        products.push(...value.cards);
      }

      return products;
    }, []);
  }

  extractCategoryFromPathname(pathname: string = ''): string {
    return pathname?.match(/\/\w+\/([\w-]+)\/?/)?.[1]?.replace(/-/g, ' ') || '';
  }

  emitEvent(event: GTMEvent): void {
    if (!isBrowser()) return;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  }

  private emitDelayedEvent(events: GTMEvent[]): NodeJS.Timeout {
    return setTimeout(() => {
      events.forEach((event) => {
        this.emitEvent(event);
      });
    }, parseInt(process.env.GATSBY_GOOGLE_TAG_TIMEOUT || '', 10) + 1000 || 3000);
  }
}

export const gtmService = new GtmService();
export * from './model.d';
