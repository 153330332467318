export const getStringWithoutTags = (str: string): string => {
  if (typeof str !== 'string') return '';
  const htmlTagsRegex = /<[^>]+>/g;
  const containsTags = htmlTagsRegex.test(str);

  return containsTags ? str?.replace(htmlTagsRegex, '') : str;
};

export const capitalize = (string: string) => {
  const lowercased = string.toLowerCase();

  return lowercased.charAt(0).toUpperCase() + lowercased.slice(1);
};
