import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';

import { gtmService } from 'utils/gtmService';

export const replaceHydrateFunction = () => (element, container, callback) => {
  loadableReady(() => {
    ReactDOM.hydrate(element, container, callback);
  });
};

export const wrapPageElement = ({ props }) => {
  const { pageTemplate } = props.pageContext || {};
  gtmService.emitPageView(pageTemplate);
};
